// DOC: https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-2
import * as amplitude from '@amplitude/analytics-browser';

const isProduction = process?.env?.ENVIRONMENT === 'production';

const sendEvent = ({ action, category, label, properties }) => {
  amplitude.track(action, {
    category,
    label,
    properties,
  });
};

const amplitudeIdentifyUser = (userId, userProperties = {}) => {
  amplitude.setUserId(userId);

  const identifyEvent = new amplitude.Identify();

  Object.entries(userProperties).forEach(([key, value]) => {
    identifyEvent.set(key, value);
  });

  amplitude.identify(identifyEvent);
};

export default ({ app }, inject) => {
  amplitude.init(process.env.AMPLITUDE_API_KEY, { autocapture: true });

  if (isProduction) {
    inject('amplitude', amplitude);

    inject('sendEvent', sendEvent);

    inject('amplitudeIdentifyUser', amplitudeIdentifyUser);
  } else {
    inject('sendEvent', ({ action, category, label, properties }) => {
      console.log(
        `AMPLITUDE: Evento registrado en desarrollo: ${action}`,
        `Categoria: ${category}`,
        `Etiqueta: ${label}`,
        `Propiedades: ${properties}`,
      );
    });

    inject('amplitudeIdentifyUser', (userId, userProperties) =>
      console.log(
        'AMPLITUDE: Identificación de usuario desactivada en desarrollo.',
      ),
    );
  }
};
