/**
 * @fileoverview This file contains the Vue plugin that registers various providers and injects them into the Vue instance.
 * The providers include Preferences, Toasts, Banners, DataSource, Authentication, License, FormValidations, Utilities, Calendars, Cart, Historics, Stampings, Dispersions, Policies, and Permissions.
 * It also sets up an error handler that sends error information to a remote server for logging.
 */

import Vue from 'vue';
import fa from '~/components/ui/icons/fa.vue';
import global from '~/mixins/global.js';
import Authentication from '~/providers/Authentication.providers';
import Banners from '~/providers/Banners.provider';
import Calendars from '~/providers/Calendars.provider';
import Cart from '~/providers/Cart.provider';
import DataSource from '~/providers/DataSource.provider';
import FormValidations from '~/providers/FormValidations.provider';
import Historics from '~/providers/Historics.provider';
import License from '~/providers/License.provider';
import Lists from '~/providers/Lists.provider';
import Permissions from '~/providers/Permissions.provider';
import Platform from '~/providers/Platform.provider';
import Preferences from '~/providers/Preferences.provider';
import Stampings from '~/providers/Stampings.provider';
import Toasts from '~/providers/Toast.provider';
import Utilities from '~/providers/Utilities.provider';
import TerminationAndSettlement from '~/providers/TerminationAndSettlement.provider';

// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('fa', fa);
Vue.mixin(global);

/**
 * Vue plugin that registers various providers and injects them into the Vue instance.
 * @param {Object} context - The context object containing app and store.
 * @param {Function} inject - The inject function to inject the providers into the Vue instance.
 */
export default ({ app, store, env }, inject) => {
  const disableRoles = env?.DISABLE_ROLES ?? false;
  const preferences = new Preferences();
  const toasts = new Toasts();
  const banners = new Banners();
  const dataSource = new DataSource(app);
  const authentication = new Authentication(dataSource, app);
  const license = new License(dataSource, app);
  const formValidations = new FormValidations();
  const utilities = new Utilities(toasts);
  const calendars = new Calendars();
  const cart = new Cart();
  const platform = new Platform();
  const historics = new Historics();
  const stampings = new Stampings();
  const permissions = new Permissions(authentication, disableRoles);
  const lists = new Lists();
  const terminationAndSettlement = new TerminationAndSettlement();

  inject('preferences', preferences);
  inject('toasts', toasts);
  inject('banners', banners);
  inject('dataSource', dataSource);
  inject('authentication', authentication);
  inject('license', license);
  inject('formValidations', formValidations);
  inject('utilities', utilities);
  inject('calendars', calendars);
  inject('cart', cart);
  inject('platform', platform);
  inject('historics', historics);
  inject('stampings', stampings);
  inject('permissions', permissions);
  inject('lists', lists);
  inject('terminationAndSettlement', terminationAndSettlement);

  Vue.$preferences = preferences;
  Vue.$toasts = toasts;
  Vue.$banners = banners;
  Vue.$dataSource = dataSource;
  Vue.$authentication = authentication;
  Vue.$license = license;
  Vue.$formValidations = formValidations;
  Vue.$utilities = utilities;
  Vue.$calendars = calendars;
  Vue.$cart = cart;
  Vue.$platform = platform;
  Vue.$historics = historics;
  Vue.$stampings = stampings;
  Vue.$permissions = permissions;
  Vue.$lists = lists;
  Vue.$terminationAndSettlement = terminationAndSettlement;
};
