import * as msal from '@azure/msal-browser';

export default ({ $app, $config, route }, inject) => {
  // Config object to be passed to Msal on creation

  const msalConfig = {
    auth: {
      tenant: $config.msal.tenant, // 'bd2e3d89-7c32-4ed7-a24f-09586d9d0e45',
      clientId: $config.msal.clientId, // 'da66211e-f871-4363-9afb-ad7ab48a6066',
      authority: $config.msal.authority, // 'https://login.microsoftonline.com/Notengoempresa290.onmicrosoft.com',
      redirectUri: '', // It has to be configured on your Azure tenant
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };
  // Create the MSAL application object
  const myMSAL = new msal.PublicClientApplication(msalConfig);

  // Catch redirect callbacks
  myMSAL
    .handleRedirectPromise()
    .then(handleResponse)
    .catch((err) => {
      console.error(err);
    });
  // Handle the response from AAD
  function handleResponse(resp) {
    if (resp !== null) {
      myMSAL.setActiveAccount(resp.account);
    }
  }

  // Add here scopes for id token to be used at MS Identity Platform endpoints.

  // Sign in the user
  async function signIn() {
    const loginRequest = {
      scopes: [$config.msal.scope],
      redirectUri: window.location.href.split('?').at(0),
    };
    return await myMSAL.loginPopup(loginRequest);
  }

  // Get the account object of the signed-in user
  function getAccounts() {
    return myMSAL.getAllAccounts();
  }

  // Sign out the user
  function signOut(accountId) {
    const logoutRequest = {
      account: myMSAL.getAccountByHomeId(accountId),
    };

    return myMSAL.logoutRedirect(logoutRequest);
  }

  // Get acces token for calling API's
  function getToken() {
    const activeAccount = myMSAL.getAllAccounts()[0];
    const tokenRequest = {
      scopes: [
        'https://<yourtentant>.onmicrosoft.com/<yourapiexposed>/<yourapi.permission>',
      ],
      account: activeAccount,
    };

    return myMSAL
      .acquireTokenSilent(tokenRequest)
      .then(function (accessTokenResponse) {
        const accessToken = accessTokenResponse.accessToken;
        return accessToken;
      })
      .catch(function (error) {
        // eslint-disable-next-line no-undef
        if (error instanceof InteractionRequiredAuthError) {
          myMSAL
            .acquireTokenPopup(tokenRequest)
            .then(function (accessTokenResponse) {
              const accessToken = accessTokenResponse.accessToken;
              return accessToken;
            })
            .catch(function (error) {
              console.error(error);
            });
        } // catch any errors during the authentication process
      });
  }

  // inject functions on vue instance
  inject('msal', {
    signIn,
    getAccounts,
    signOut,
    getToken,
    myMSAL,
  });
};
